import React from "react";
import { useSelector } from "react-redux";

import { Theme, ThemeProvider, makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import { theme } from "@legup/legup-react-components";

import { logException, renderStringWithHTML } from "../infra/utils";
import { Event, trackEventLegacy } from "../infra/tracking";

import strings from "../infra/constants/strings";

const useStyles = makeStyles((uiTheme: Theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  content: {
    margin: "65px 0 0",
    flexGrow: 1,
    padding: uiTheme.spacing(3),
    minHeight: "100%",
  },
  title: {
    flexGrow: 1,
    justifyContent: "flex-start",
    justifySelf: "center",
    display: "flex",
    marginLeft: "16px",
  },
  divider: {
    height: 55,
    backgroundColor: "white",
  },
}));

function renderCopyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://kinside.com/">
        {strings.companyName}
      </Link>{" "}
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

function NotFound({ props, statusCode }) {
  const classes = useStyles(props);
  const groups = useSelector((state: any) => state.clientStateReducer.groups);
  const email = useSelector((state: any) => state.clientStateReducer.email);

  React.useEffect(() => {
    logException("page not found");
    trackEventLegacy(email, groups, Event.notFoundPage, window.location.href);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        <main className={classes.content}>
          <div />
          <Box height="100%">
            <Typography className={classes.title} variant="h1" noWrap>
              <img
                src="https://res.cloudinary.com/kinside/image/upload/v1643079260/app_assets/kinside_logo/kinside-word-dark.svg"
                height="30"
                alt={strings.altLogo}
                className="logo"
              />
            </Typography>
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              align="center"
              className={classes.title}
            >
              {renderStringWithHTML(strings.notFoundPage.text)}
            </Typography>
          </Box>
        </main>
        <Box pt={4}>
          <Divider />
          {renderCopyright()}
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default NotFound;
